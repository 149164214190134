import { getAuth0Instance } from '../auth';
import axios from 'axios';
export default {
  getAuthenticated(url, options = null) {
    //url = 'https://www.bauvisor.com' + url;
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .get(url, options)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        })
        .catch(error => reject(error)); // or try .loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    });
  },
  postAuthenticated(url, options = null) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .post(url, options)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
    });
  },
  deleteAuthenticated(url, bodyData = null) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .delete(url, { data: bodyData })
            .then(response => resolve(response.data))
            .catch(error => {
              /*
              if(error.response && error.response.status == 403) {

              }*/
              reject(error);
            });
        });
    });
  },
  updateAuthenticated(url, options = null) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .patch(url, options)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
    });
  },
  downloadAuthenticated(url) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          axios({
            url,
            method: 'GET',
            responseType: 'blob' // important
          })
            .then(response => {
              const fileName = response.headers['x-filename'];
              const url = window.URL.createObjectURL(new Blob(['\ufeff', response.data])); // "\ufeff" stands for UTF-8 with BOM - BOM is important, so Excel will interpret German umlaut correctly
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              resolve();
            })
            .catch(err => reject(err));
        });
    });
  },
  uploadAuthenticated(url, file, field) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          var formData = new FormData();

          formData.append('file', file);
          formData.append('originalFileName', encodeURI(file.name));

          if (field) formData.append('field', field);

          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' } })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
    });
  },
  deleteUploadAuthenticated(url, file, field) {
    return new Promise((resolve, reject) => {
      getAuth0Instance()
        .getTokenSilently()
        .then(token => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // set current token to all requests
          //Vue.prototype.$axios.defaults.headers.common["User"] = Vue.prototype.$user.profile.email;
          axios
            .post(url, { file, field })
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
    });
  }
};
