const round = number => {
  if (!number || isNaN(number)) return null;
  return Math.round(number * 100) / 100;
};
module.exports = {
  months: [
    '0',
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  toDateSeries(array, dateColumn, valueColumn, timeSpanInDays = 6) {
    const todayDate = new Date();
    const refDate = new Date();
    refDate.setDate(todayDate.getDate() - timeSpanInDays);

    var daysOfYear = [];
    for (var d = refDate; d <= todayDate; d.setDate(d.getDate() + 1)) {
      const item = {};
      const key = new Date(d).toISOString().split('T')[0];
      const originItem = array.find(x => x[dateColumn] == key);

      item[dateColumn] = key;

      item[valueColumn] = originItem && originItem[valueColumn] ? originItem[valueColumn] : 0;

      daysOfYear.push(item);
    }
    return daysOfYear;
  },
  isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  },
  numberToCurrency(input, showDigits = true) {
    if (typeof input == 'string') input = parseFloat(input);
    showDigits = showDigits == true || showDigits == 'true' ? true : false;
    if (input) {
      return (
        round(input).toLocaleString('de-DE', {
          minimumFractionDigits: showDigits ? 2 : 0,
          maximumFractionDigits: showDigits ? 2 : 0
        }) + ' €'
      );
    } else {
      /*
      if (showDigits) return '0,00 €';
      else return '0 €';*/
      return '-';
    }
  },
  formatCurrency(number, settingsStore) {
    return this.numberToCurrency(round(number), settingsStore.settings['Centbetraege']);
  },
  numberWithMaxFrictions(input, maxFrictions) {
    if (input) {
      return parseFloat(round(input)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFrictions
      });
    } else
      return parseFloat(0).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFrictions
      });
  },
  numberWithMaxFrictionsDE(input, maxFrictions) {
    if (input) {
      return parseFloat(round(input)).toLocaleString('de-DE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxFrictions
      });
    }
  },
  deleteObjectFromArray(array, object) {
    array.splice(
      array.findIndex(item => item == object),
      1
    );
  },
  deleteItemFromArray(array, findFunction) {
    const index = array.findIndex(findFunction);
    if (index == -1) return console.log('not found');
    array.splice(index, 1);
    return array;
  },
  deleteIndexFromArray(array, index) {
    array.splice(index, 1);
    return array;
  },
  germanDateToISODate(dateString) {
    const dateParts = dateString.split('.');
    var date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]), 0, 0, 0, 0);
    return date.toISOString();
  },
  germanDateToSQLDate(dateString) {
    const dateParts = dateString.split('.');
    return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
  },
  browserDateToSQLDate(dateString) {
    if (dateString && dateString == '') return null;
    if (!dateString) return null;
    const dateParts = dateString.split('-');
    return dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2];
  },
  SQLDateToGermanDate(dateString, textIfNull = '') {
    if (dateString)
      return new Date(dateString).toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    else return textIfNull;
  },
  SQLDateToGermanDateTime(dateTimeString, textIfNull = '') {
    if (dateTimeString) return new Date(dateTimeString).toLocaleString('de-DE').replaceAll(', ', '  ');
    else return textIfNull;
  },
  SQLDateToBrowserDate(dateString) {
    if (!dateString) return null;
    return dateString.split('T')[0];
  },
  currentGermanDate() {
    return new Date().toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  },
  currentBrowserDate() {
    const parts = new Date().toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/');
    const retVal = parts[2] + '-' + parts[0] + '-' + parts[1];
    return retVal;
  },
  round
};
