import Vue from 'vue';
import VueRouter from 'vue-router';

import { authGuard } from '../auth/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'fefwfwe',
    /*
    beforeEnter: (to, from, next) => {
      console.log('zzz adam', to, from, next);
      // reject the navigation
      return next({ path: '/kunden' });
    },*/

    redirect: () => {
      if (Vue.prototype.$auth?.user?.IsSU == true) {
        return '/home';
      } else {
        if (Vue.prototype.$auth?.user?.IsSU == undefined) {
          // let this flow run through without doing anything;
        } else {
          return '/kunden'; // '/kunden';
        }
      }
    },
    //beforeEnter: authGuard,
    meta: {
      //requiredRole: 'User'
      authRule: x => x.IsSU == true,
      authRuleRedirect: '/home',
      authRuleRedirectElse: '/kunden'
    }
  },
  {
    path: '/kunden/:id/projekte/create',
    name: 'Kundenprojekt anlegen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/ProjectsFormCreate.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:id/projekte',
    name: 'Kundenprojekte',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/ProjectsListView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/create',
    name: 'Kunde',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundenformular" */ '../views/CustomersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:id/projekte/:pid/export',
    name: 'Kundenprojekt exportieren',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/ProjectExportPage.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:id/projekte/:pid',
    name: 'Kundenprojekt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/ProjectsForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:id/projekte/:pid/users/:uid',
    name: 'Projektuserdetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/UsersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:cid/users/create',
    name: 'Neuer User',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/UsersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden/:id/users/:uid',
    name: 'Kundenuserdetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/UsersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },

  {
    path: '/kunden/:id',
    name: 'Kundedetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/CustomersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/kunden',
    name: 'Kunden',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for thifs route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kunden" */ '../views/CustomersListView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
      //authRule: x => x.IsSU == true,
      //authRuleRedirect: '/user'
    }
  },

  {
    path: '/projekte/:pid/export',
    name: 'Projekt exportieren',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundendetail" */ '../views/ProjectExportPage.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/projekte/create',
    name: 'Projekt erstellen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundenformular" */ '../views/ProjectsFormCreate.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/projekte/:pid',
    name: 'Projekt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundenformular" */ '../views/ProjectsForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/projekte',
    name: 'Projekte',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projekte" */ '../views/ProjectsListView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/user/:uid',
    name: 'Userdetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kundenformular" */ '../views/UsersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/users/create',
    name: 'Neuer Benutzer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/UsersForm.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/users',
    name: 'Users',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/UsersListView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/home',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/DashboardView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/logs',
    name: 'Logs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/LogsListView.vue'),
    beforeEnter: authGuard,
    meta: {
      requiredRole: 'User'
    }
  },
  {
    path: '/pages/403',
    name: 'Keine Berechtigung',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user" */ '../views/errors/ErrorPage403.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
